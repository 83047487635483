/**
 * Created by spepa on 10/05/20
 */

var PhotoClover = cc.Sprite.extend({
    ctor: function (cloverData, photos) {
        this._super(bundles.game.frames["mission_star_gray_" + cleverapps.Random.random(0, 2)]);
        this.photos = photos;
        var images = [photos.imageA, photos.imageB];

        images[(photos.clovers.length + 1) % 2].addChild(this);
        this.setPositionRound(cloverData.x * resolutionScale, cloverData.y * resolutionScale);

        var maxSize = cleverapps.styles.PhotoClover.maxSize;
        var baseScale = Math.min(maxSize / this.height, maxSize / this.width);
        this.setScale(baseScale + 0.5 * (Math.random() - 0.5));
        this.baseOpacity = cleverapps.Random.random(80, 100);
        this.setOpacity(this.baseOpacity);

        var doppel = this.doppel = new cc.Node();
        doppel.setAnchorPoint(0.5, 0.5);
        doppel.setContentSize2(this.width * this.scale, this.height * this.scale);
        images[photos.clovers.length % 2].addChild(doppel);
        doppel.setPositionRound(this.getPosition());

        cleverapps.UI.onClick(this, this.cloverFound.bind(this));
        cleverapps.UI.onClick(doppel, this.cloverFound.bind(this));
    },

    cloverFound: function () {
        if (this.photos.blurred) {
            return;
        }

        if (cleverapps.forces.isRunningForce()) {
            cleverapps.forces.closeRunningForce();
        }
        cleverapps.audio.playSound(bundles.game.urls.clover_found_sfx);

        var ind = this.photos.clovers.indexOf(this);
        this.photos.clovers.splice(ind, 1);

        var callback = function () {
            this.photos.differences.addClover(Mission.TYPE_LETTER, 1);
        }.bind(this);

        var missionType = Game.currentGame.getMissionType();
        var target = cleverapps.aims.getTarget("mission_reward" + missionType);
        var flying = new cleverapps.Spine(bundles.game.jsons.clover_found_json);
        flying.setAnimation(0, "animation", false);
        flying.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(flying);
        flying.replaceParentSamePlace(cleverapps.scenes.getMovingNode(target));

        this.removeFromParent();
        this.doppel.removeFromParent();

        flying.runAction(new cc.Sequence(
            new cc.DelayTime(0.7),
            AnimationsLibrary.animateCollect(flying, "mission_reward" + missionType, {
                scale: 1
            }),
            new cc.CallFunc(callback),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.styles.PhotoClover = {
    maxSize: 60
};