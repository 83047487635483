/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["knockoutTutorial"] = {
    priority: -1001,
    type: Placements.FREE_FOCUS,

    filter: function () {
        return cleverapps.knockoutTutorial.isAvailable();
    },

    action: function () {
        cleverapps.focusManager.display({
            focus: "KnockoutTutorialStep" + cleverapps.knockoutTutorial.step,
            control: cleverapps.knockoutTutorial.control,
            action: function (f) {
                cleverapps.knockoutTutorial.run(f);
            }
        });
    }
};