/**
 * Created by slava on 4/3/20
 */

var DifferencesScene = GameSceneBase.extend({
    onSceneLoaded: function () {
        this._super();

        var differences = this.game;

        this.createBoard();

        differences.on("block", this.coolDown.bind(this));

        cleverapps.knockoutTutorial.onFirstMoveForceListener = this.createListener(this.firstMoveForce.bind(this));
        differences.competition.onChangeResultsListeners.hitAlarm = this.createListener(this.hintAlarm.bind(this));
    },

    createBoard: function () {
        this.photos = new PhotoViews(this.game, this);
        this.photos.setAnchorPoint(0.5, 0.5);
        this.addChild(this.photos);

        if (this.photos.isCloversForceAvailable()) {
            this.photos.showCloversForce();
        }

        var styles = cleverapps.styles.GameScene;

        var brushButton, discoverButton;

        if (this.game.brushBooster) {
            brushButton = new BrushButtonBooster(this.game.brushBooster);
        }

        if (this.game.discoverBooster) {
            discoverButton = new DiscoverButtonBooster(this.game.discoverBooster);
        }

        if (brushButton || discoverButton) {
            this.boosters = new cleverapps.Layout([brushButton, discoverButton].filter(Boolean), {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.boosters.margin[cleverapps.resolution.mode]
            });
            this.boosters.setPositionRound(styles.boosters.position);
            this.addChild(this.boosters);
        }

        if (brushButton) {
            this.brushButton = new HidingNode(brushButton, cleverapps.resolution.mode === cleverapps.WideMode.SQUARE ? cleverapps.UI.HORIZONTAL : cleverapps.UI.VERTICAL);
            cleverapps.focusManager.registerControl(brushButton.getControlId(), this.brushButton);
        }

        if (discoverButton) {
            this.discoverButton = new HidingNode(discoverButton, cleverapps.resolution.mode === cleverapps.WideMode.SQUARE ? cleverapps.UI.HORIZONTAL : cleverapps.UI.VERTICAL);
            cleverapps.focusManager.registerControl(discoverButton.getControlId(), this.discoverButton);
        }
    },

    setupChildren: function () {
        this._super();

        if (this.boosters) {
            this.boosters.setOptions({
                direction: cleverapps.UI.HORIZONTAL,
                margin: cleverapps.styles.GameScene.boosters.margin[cleverapps.resolution.mode]
            });
        }
    },

    scaleGameField: function () {
        var scene = cleverapps.resolution.getSceneSize();
        var overlappingList = [this.boosters, this.upMenuContainer, this.playersView].filter(Boolean);

        var centerX = scene.width / 2;
        if (cleverapps.resolution.mode === cleverapps.WideMode.SQUARE) {
            centerX += this.playersView.width / 2;
        }
        cleverapps.UI.inflateToBoundaries(this.photos, overlappingList, {
            centerPoint: this.convertToWorldSpace(cc.p(centerX, scene.height / 2)),
            padding: cleverapps.styles.GameScene.padding,
            maxScale: 1.5
        });
    },

    hintAlarm: function (id) {
        var totalPoints = this.game.competition.options.algo.maxResult;
        var opponentPoints = this.game.competition.onGetOpponent().amount;

        if (id !== this.game.competition.player.id && opponentPoints === totalPoints - 1) {
            var booster = this.game.discoverBooster;
            if (booster && !booster.manualAlarm) {
                booster.startHint(true);

                cleverapps.audio.playSound(bundles.game.urls.exclamation_sfx);
                cleverapps.audio.fadeOut();
                this.runAction(new cc.Sequence(
                    new cc.DelayTime(0.4),
                    new cc.CallFunc(function () {
                        cleverapps.audio.insertDisk(bundles.game.urls.intense_bgm);
                    })
                ));
            }
        }
    },

    firstMoveForce: function (f) {
        this.photos.firstMoveForce(f);
    },

    coolDown: function () {
        var coolDownNode;

        cleverapps.focusManager.display({
            focus: "CoolDown",
            actions: [
                function (f) {
                    this.photos.toggleBlur(true, f);
                }.bind(this),

                function (f) {
                    var duration = cleverapps.parseInterval(Differences.BLOCK_DURATIONS[this.game.inflamer.stage]);
                    var warningsLeft = Differences.ALLOWED_MISTAKES - this.game.inflamer.stage;

                    coolDownNode = new CoolDownNode(duration, warningsLeft, this.game.discoverBooster, this.closeCoolDownNode.bind(this));
                    this.addChild(coolDownNode, 1);

                    coolDownNode.showUp(f);
                }.bind(this),

                function (f) {
                    if (cleverapps.forces.isAvailable(coolDownNode, Forces.COOLDOWN_FORCE)) {
                        cleverapps.forces.create(coolDownNode, Forces.COOLDOWN_FORCE);
                        cleverapps.forces.onceForceClosed = this.closeCoolDownNode.bind(this);
                    }

                    this.closeCoolDownCallback = function () {
                        if (!cleverapps.windows.isActive() || this.game && this.game.outcome !== GameBase.OUTCOME_UNKNOWN) {
                            f();
                            return;
                        }

                        var onceNoWindowsListener = cleverapps.focusManager.onceNoWindowsListener;

                        cleverapps.focusManager.onceNoWindowsListener = cleverapps.once(function () {
                            if (onceNoWindowsListener) {
                                onceNoWindowsListener();
                            }
                            f();
                        });
                    };
                }.bind(this),

                function (f) {
                    coolDownNode.animateClose(f);
                },

                function (f) {
                    if (this.game && this.game.outcome === GameBase.OUTCOME_UNKNOWN) {
                        this.photos.toggleBlur(false);
                    }

                    f();
                }.bind(this)
            ]
        });
    },

    closeCoolDownNode: function () {
        if (cleverapps.forces.isRunningForce(Forces.COOLDOWN_FORCE)) {
            cleverapps.forces.closeRunningForce();
        }

        if (this.closeCoolDownCallback) {
            this.closeCoolDownCallback();
            this.closeCoolDownCallback = undefined;
        }
    },

    coolDownNodeActive: function () {
        return Boolean(this.closeCoolDownCallback);
    },

    introControls: function () {
        if (this.game && this.game.outcome === GameBase.OUTCOME_VICTORY) {
            return ["photos"];
        }
        return undefined;
    }
});

GameScene = DifferencesScene;

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    padding: 30,

    boosters: {
        margin: [
            86,
            26,
            46
        ],

        position: [
            { x: { align: "center" }, y: { align: "bottom", dy: 50 } },
            { x: { align: "left", dx: 35 }, y: { align: "center", dy: -157 } },
            { x: { align: "center" }, y: { align: "bottom", dy: 50 } }
        ]
    }
});